import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Креативний Маркетинг
			</title>
			<meta name={"description"} content={"Креативний Маркетинг - професійне маркетингове агентство. Пропонуємо повний спектр послуг для розвитку вашого бізнесу: від стратегії до реалізації."} />
			<meta property={"og:title"} content={"Креативний Маркетинг"} />
			<meta property={"og:description"} content={"Креативний Маркетинг - професійне маркетингове агентство. Пропонуємо повний спектр послуг для розвитку вашого бізнесу: від стратегії до реалізації."} />
			<meta property={"og:image"} content={"https://uslinders.com/img/1.png"} />
			<link rel={"shortcut icon"} href={"https://uslinders.com/img/2628985.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uslinders.com/img/2628985.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://uslinders.com/img/2628985.png"} />
			<meta name={"msapplication-TileImage"} content={"https://uslinders.com/img/2628985.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="140px 0" sm-padding="40px 0 40px 0" background="url(https://uslinders.com/img/6.jpg) center/cover">
			<Override slot="SectionContent" sm-align-items="center" />
			<Box max-width="800px" padding="50px 50px 80px 50px" background="--color-light" color="--dark">
				<Text as="h1" font="--headline2" margin="0 0 12px 0">
				Креативний Маркетинг - Про нас
				</Text>
				<Text font="--base">
				Дізнайтеся більше про маркетингове агентство Креативний Маркетинг. Ми пропонуємо інноваційні рішення для вашого бізнесу.
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			flex-direction="column"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Text margin="8px 0px 32px" font="--headline2" color="--dark" width="100%">
			Наша місія
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 0px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Місія Креативний Маркетинг полягає в наданні високоякісних маркетингових послуг, які допомагають нашим клієнтам досягати їх бізнес-цілей. Ми прагнемо бути лідерами в галузі, пропонуючи інноваційні рішення та креативні підходи до вирішення задач.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 16px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Наша історія
				<br/><br/>
				Креативний Маркетинг був заснований групою досвідчених маркетологів, які побачили потребу в інноваційних і креативних підходах до маркетингу. З моменту заснування ми допомогли сотням клієнтів досягти успіху. Ми пишаємося нашими досягненнями та прагнемо продовжувати рости і розвиватися.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 16px 0px 0px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-margin="0px 0px 16px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				Наша команда
				<br/><br/>
				
				У Креативний Маркетинг працюють тільки висококваліфіковані спеціалісти. Наші маркетологи, аналітики, контент-менеджери та дизайнери мають великий досвід роботи в галузі, що дозволяє нам забезпечити найвищий рівень обслуговування. Ми постійно підвищуємо кваліфікацію наших співробітників, щоб бути в курсі всіх новинок та змін на ринку.
			</Text>
			<Text
				margin="0px"
				font="--base"
				color="--grey"
				width="25%"
				padding="0px 16px 0px 16px"
				md-width="50%"
				md-padding="0px 0px 0px 16px"
				md-margin="0px 0px 32px 0px"
				sm-width="100%"
				sm-padding="0px 0px 0px 0px"
			>
				Наші цінності
				<br/><br/>
				Основні цінності Креативний Маркетинг – це креативність, інновації, професіоналізм та клієнтоорієнтованість. Ми завжди діємо в інтересах наших клієнтів і забезпечуємо максимальну ефективність наших рішень. Наша мета – стати вашим надійним партнером у сфері маркетингу.

			</Text>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="30px 0 30px 0" md-padding="40px 0 40px 0" quarkly-title="About-13">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="grid"
				grid-gap="32px"
				sm-grid-gap="16px"
				grid-template-columns="4fr 5fr"
				align-items="center"
				width="50%"
				lg-width="100%"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Image
					src="https://uslinders.com/img/7.jpg"
					object-fit="cover"
					width="100%"
					height="80%"
					lg-height="100%"
				/>
				<Image
					src="https://uslinders.com/img/9.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					max-height="460px"
				/>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				padding="42px 42px 42px 42px"
				lg-width="100%"
				md-padding="24px 24px 24px 24px"
				md-margin="24px 0px 0px 0px"
				sm-align-items="flex-start"
				sm-padding="24px 24px 24px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Приєднуйтесь до нас і ростіть разом з нами!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="center"
					sm-text-align="left"
				>
					Довіртеся Креативний Маркетинг і скористайтеся нашими професійними послугами у сфері маркетингу. Ми готові допомогти вам досягти нових висот.
				</Text>
				<Link
					href="/contact"
					color="--lightD1"
					text-decoration-line="initial"
					padding="11px 24px 12px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					border-radius="8px"
					letter-spacing="1px"
				>
					Дізнатися більше
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});